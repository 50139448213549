import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logo2, logo_footer, payment_img } from '../../Data/Images/Home';
import {
  SubscribeNow,
  RecentPost,
  UsefulLinks,
  StayConnected,
  About,
  TermsConditions,
  Policy,
  FAQs,
  Blogs,
  ContactUs_,
  Partner,
  SubscribeFirstPurchase,
  EnterEmailHere,
} from '../../Constants';
import { useGetBlogsQuery } from '../../features/blogApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useSubscribeMutation } from '../../features/visotorApiSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  selectSlug,
  selectTakeTheTestTitle,
} from '../../features/contentsSlice';
import { ExternalLink } from 'react-external-link';

const Footer = () => {
  const lang = 'en';
  const [data, setData] = useState([]);
  const [email, setEmail] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const { t } = useTranslation();
  const history = useNavigate();
  const takeTheTestTitle = useSelector(selectTakeTheTestTitle);
  const slug = useSelector(selectSlug);

  const [subscribe] = useSubscribeMutation();

  const {
    data: fetchBlogs,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBlogsQuery({ lang: lang, page: 1, rows: 3, category: 'blog' });

  useEffect(() => {
    if (isSuccess) {
      setData(fetchBlogs.data);
    }
  }, [fetchBlogs]);

  const onLinkClick = (e, route) => {
    e.preventDefault();

    history(`${route}`);
    window.scrollTo(0, 0);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();

    console.log(email);
    setEmailError('');

    if (email === '' || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError('Enter valid email.');
      return false;
    }

    try {
      const res = await subscribe({ email: email }).unwrap();

      //console.log(res.message)
      if (res.message === 'Already subscribed.') {
        setEmailError(res.message);
      } else {
        setEmailMessage(res.message);
      }
    } catch (err) {
      console.log(err);
      setEmailError('Something is wrong!');
    }
  };

  return (
    <Fragment>
      <footer className='section-padding'>
        <div className='container'>
          <div className='row gy-4 gx-sm-5'>
            <div className='col-lg-3'>
              <div className='logo'>
                <img src={logo2} alt='' className='img-fluid' width={120} />
              </div>
              <div className='mt-5'>
                {/*<a className="btn btn-custom-tertiary" href={`${process.env.PUBLIC_URL}/quiz`}>{takeTheTestTitle}</a>*/}
                <Link to={slug}>
                  <span className='btn btn-custom-tertiary'>
                    {takeTheTestTitle}
                  </span>
                </Link>
              </div>
              {/*<h4>{t(SubscribeFirstPurchase)}</h4>*/}
              {/*<form>*/}
              {/*  <div className="mb-3">*/}
              {/*    /!* <label for="" class="form-label">Name</label> *!/*/}
              {/*    <input*/}
              {/*        type="text"*/}
              {/*        onChange={(e) => setEmail(e.target.value)}*/}
              {/*        className="form-control" placeholder={t(EnterEmailHere)} aria-describedby="helpId" />*/}
              {/*    {emailError === '' ? '' : <span className="text-danger">{emailError}</span>}*/}
              {/*    {emailMessage === '' ? '' : <span className="text-success">{emailMessage}</span>}*/}
              {/*  </div>*/}
              {/*  <button type="button" className="btn btn-custom w-100" onClick={handleSubscribe}>{t(SubscribeNow)}</button>*/}
              {/*</form>*/}
            </div>
            <div className='col-lg-3'>
              <div className='footer-item'>
                <h1 className='title2'>{t(RecentPost)}</h1>
                {data.map((blog, index) => (
                  <a
                    key={index}
                    href={`${process.env.PUBLIC_URL}/blogs/${blog.slug}`}
                  >
                    <div className='recent-blog-item'>
                      <div className=''>
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}${blog.picture}`}
                          alt=''
                          width={96}
                        />
                      </div>
                      <div>
                        <p>{blog.contents.substring(0, 50)}</p>
                        <h6>{blog.bdate}</h6>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='footer-item'>
                <h1 className='title2'>{t(UsefulLinks)}</h1>
                <ul className='useful-links'>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/about')}>{t(About)}</a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/impressum')}>
                      Impressum
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/legal')}>
                      {t(TermsConditions)}
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/policy')}>
                      {t(Policy)}
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/faqs')}>{t(FAQs)}</a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/blogs')}>{t(Blogs)}</a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/contactus')}>
                      {t(ContactUs_)}
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => onLinkClick(e, '/partner')}>
                      {t(Partner)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='footer-item'>
                <h1 className='title2'>{t(StayConnected)}</h1>
                <ul className='social'>
                  <li>
                    <a
                      href='https://www.instagram.com/we_riise/'
                      target='_blank'
                    >
                      <i className='fab fa-instagram' />
                    </a>
                  </li>
                  {/*<li><a href><i className="fab fa-twitter" /></a></li>*/}
                  {/*<li><a href><i className="fab fa-facebook-f" /></a></li>*/}
                  {/*<li><a href><i className="fab fa-youtube" /></a></li>*/}
                </ul>

                <img
                  style={{ width: '165px' }}
                  src={payment_img}
                  alt=''
                  className='img-fluid mt-4'
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
