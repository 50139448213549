import { apiSlice } from "../app/api/apiSlice";

export const QuizApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: (lang) => ({ url: `quiz?lang=${lang}` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    answers: builder.mutation({
      query: (payload) => ({
        url: "quiz/answersweb",
        method: "POST",
        body: { ...payload },
      }),
    }),
    saveIncompleteQuiz: builder.mutation({
      query: (payload) => ({
        url: "quiz/incomplete",
        method: "POST",
        body: { ...payload },
      }),
    }),
    getIncompleteQuiz: builder.query({
      query: (id) => ({ url: `quiz/incomplete?id=${id}` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getAssets: builder.query({
      query: (section) => ({ url: `asset?section=${section}` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getIngredients: builder.query({
      query: () => ({ url: `ingredient/all` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getIngredientsCodes: builder.query({
      query: () => ({ url: `ingredient/allIngredients` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    updateQuizIngredients: builder.mutation({
      query: (payload) => ({
        url: "ingredient/updateQuizIngredients",
        method: "POST",
        body: { ...payload },
      }),
    }),
    getIngredientPrices: builder.query({
      query: (lang) => ({ url: `ingredientPrices?lang=${lang}` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getProductPrices: builder.query({
      query: () => ({ url: `productPrices` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getNutritionists: builder.query({
      query: () => ({ url: `nutritionist` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useAnswersMutation,
  useGetIncompleteQuizQuery,
  useSaveIncompleteQuizMutation,
  useGetAssetsQuery,
  useGetIngredientsQuery,
  useGetIngredientsCodesQuery,
  useUpdateQuizIngredientsMutation,
  useGetIngredientPricesQuery,
  useGetProductPricesQuery,
  useGetNutritionistsQuery,
} = QuizApiSlice;
