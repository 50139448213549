import React from "react";
import { Suspense, useEffect, useState } from "react";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import PrivateRoute from "./PrivateRoute";
import AppLayout from "../Layout/Layout";
import RouteChangeTracker from "./RouteChangeTracker"; // Import RouteChangeTracker

import SignUps from "../Auth/SignUps";
import Logins from "../Auth/Logins";
import SignUpComplete from "../Auth/SignUpComplete";

import Dashboard from "../Components/Customer/Dashboard";
import Customer from "../Components/Customer/Customer";
import ProductSuggestion from "../Components/Customer/ProductSuggestion";

import CartScreen from "../Components/cart/CartScreen";
import OrderComplete from "../Components/Checkout/OrderComplete";
import CreatePassword from "../Components/Customer/CreatePassword";

import Loader from "../Layout/Loader";
import i18n from "i18next";

import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "../Components/Home";
import ChangePassword from "../Components/Customer/ChangePassword";
import Profile from "../Components/Customer/Profile";
import CustomerAddress from "../Components/Customer/CustomerAddress";
import CustomerSubscriptions from "../Components/Customer/CustomerSubscriptions";
import CustomerOrder from "../Components/Customer/CustomerOrder";
import OrderDetails from "../Components/Customer/OrderDetails";

import CustomerInquiry from "../Components/Customer/CustomerInquiry";

import ReferralCode from "../Components/Customer/ReferralCode";
import ScrollToTop from "./ScrollToTop";
import CustomerCheckIn from "../Components/Customer/CustomerCheckIn";
import Benefits from "../Components/Customer/Benefits";
import CustomerService from "../Components/Customer/CustomerService";
import Retake from "../Components/Customer/Retake";
import QuizComparison from "../Components/Customer/QuizComparison";
import QuizComparisonMessage from "../Components/Customer/QuizComparisonMessage";
import ForgetPassword from "../Auth/ForgetPassword";
import ResetYourPassword from "../Auth/ResetPassword";

const Routers = () => {
  //const [login, setLogin] = useState(false);

  useEffect(() => {
    let abortController = new AbortController();

    //setLogin  useState(JSON.parse(localStorage.getItem('login')))[0];
    //setLogin(JSON.parse(localStorage.getItem('login')));

    const lang = localStorage.getItem("lang");
    i18n.changeLanguage(lang);
    //console.log(lang)

    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <RouteChangeTracker />

        <Routes>
          <Route element={<AppLayout />}>
            <Route path={"/"} element={<Home />} />
          </Route>

          <Route path={"/*"} element={<PublicRoutes />} />

          <Route path={"/"} element={<PrivateRoute />}>
            <Route element={<AppLayout />}>
              <Route path={`/dashboard`} element={<Dashboard />} />
              <Route path={`/customer`} element={<Customer />} />
              <Route
                path={`/customer/askspecialist`}
                element={<CustomerInquiry />}
              />
              <Route path={"/customer/benefits"} element={<Benefits />} />
              <Route path={"/customer/service"} element={<CustomerService />} />
              <Route path={"/customer/retaketest"} element={<Retake />} />

              <Route path={`/customer/checkin`} element={<CustomerCheckIn />} />

              <Route path={`/cart`} element={<CartScreen />} />

              <Route path={"/dashboard/profile"} element={<Profile />} />
              <Route
                path={"/dashboard/password"}
                element={<ChangePassword />}
              />
              <Route
                path={"/dashboard/address"}
                element={<CustomerAddress />}
              />
              <Route
                path={"/dashboard/subscription"}
                element={<CustomerSubscriptions />}
              />
              <Route path={"/dashboard/orders"} element={<CustomerOrder />} />
              <Route
                path={"/dashboard/orderDetails"}
                element={<OrderDetails />}
              />
              <Route
                path={"/dashboard/referralcode"}
                element={<ReferralCode />}
              />
              <Route
                path={"/dashboard/comparison"}
                element={<QuizComparison />}
              />
              <Route
                path={"dashboard/comparison/message"}
                element={<QuizComparisonMessage />}
              />
            </Route>
          </Route>

          <Route element={<AppLayout />}>
            <Route
              exact
              path={`/product-suggestion`}
              element={<ProductSuggestion />}
            />
          </Route>

          <Route exact path={`/login`} element={<Logins />} />
          <Route exact path={`/signup`} element={<SignUps />} />
          <Route exact path={`/forgot-password`} element={<ForgetPassword />} />
          <Route
            exact
            path={`/reset-password`}
            element={<ResetYourPassword />}
          />
          <Route exact path={`/signup/complete`} element={<SignUpComplete />} />
          <Route path={`/createpassword`} element={<CreatePassword />} />
        </Routes>
      </Suspense>

      <ScrollToTop />
    </>
  );
};

export default Routers;
