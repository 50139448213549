export const Home = "Home";
export const Products = "Products";
export const About = "About";
export const TermsConditions = "TermsConditions";
export const Policy = "Policy";
export const FAQs = "FAQs";
export const Blogs = "Blogs";
export const OurBlogs = "OurBlogs";
export const OurIngredients = "OurIngredients";
export const ContactUs_ = "ContactUs_";
export const Partner = "Partner";
export const SubscribeNow = "SubscribeNow";
export const RecentPost = "RecentPost";
export const UsefulLinks = "UsefulLinks";
export const StayConnected = "StayConnected";
export const SubscribeFirstPurchase = "SubscribeFirstPurchase";
export const HomeTitle1 = "HomeTitle1";
export const HomeTitle2 = "HomeTitle2";
export const HomeTitle3 = "HomeTitle3";

export const MixedIn = "MixedIn";
export const TakeTheTestButton = "TakeTheTestButton";
export const ProducedIn = "ProducedIn";
export const AsSeenIn = "AsSeenIn";
export const WhatAreYourWellnessGoals = "WhatAreYourWellnessGoals";
export const Vegan = "Vegan";
export const SuitableForAthletes = "SuitableForAthletes";
export const GlutenFree = "GlutenFree";
export const ColorantFree = "ColorantFree";
export const ItMattersToUs = "ItMattersToUs";

export const FreeInclude1 = "FreeInclude1";
export const FreeInclude2 = "FreeInclude2";
export const FreeInclude3 = "FreeInclude3";
export const Payments = "Payments";
export const FollowUs = "FollowUs";
export const TakeTheTest = "TakeTheTest";
export const TakeATest = "TakeATest";
export const DiscountCodes = "DiscountCodes";
export const Login = "Login";
export const LogoutTitle = "Logout";
export const LoggedOut = "LoggedOut";
export const EnterEmailHere = "EnterEmailHere";
export const Ingredients = "Ingredients";
export const Cart = "Cart";

export const AnyOtherQuestion = "AnyOtherQuestion";

export const EmailAddress = "EmailAddress";
export const ContactDetails = "ContactDetails";
export const Address = "Address";

export const SendInquiry = "SendInquiry";
export const FirstName = "FirstName";
export const LastName = "LastName";
export const Phone = "Phone";
export const Email = "Email";
export const Message = "Message";

export const Previous = "Previous";
export const YourFocusAreas = "YourFocusAreas";
export const MyFocusArea = "MyFocusArea";
export const YourFocusAreasDesc = "YourFocusAreasDesc";
export const PersonalizedFormula = "PersonalizedFormula";
export const PersonalizedFormulaDesc = "PersonalizedFormulaDesc";
export const IncludesIngredients = "IncludesIngredients";
export const Nutrient = "Nutrient";
export const Quantity = "Quantity";
export const WhyRiiseTitle = "WhyRiise";
export const SubscriptionOptions = "SubscriptionOptions";
export const SubscriptionDetailsLine = "SubscriptionDetailsLine";
export const ChooseYourPlan = "ChooseYourPlan";
export const ContinueMyCart = "ContinueMyCart";
export const Subscription = "Subscription";

export const CheckOut_ = "CheckOut_";
export const CheckOut__ = "CheckOut__";
export const YourSupplements = "YourSupplements";
export const CapsulePerDay = "CapsulePerDay";
export const YourOrder = "YourOrder";
export const SubTotal = "SubTotal";
export const AfterDiscount = "after discount";
export const Shipping = "Shipping";
export const Total = "Total";
export const Submit = "Submit";
export const Cancel = "Cancel";

export const RecipientDetails_ = "RecipientDetails";
export const CompanyName = "CompanyName";
export const ShippingDetails = "ShippingDetails";
export const CountryRegion = "CountryRegion";
export const StreetAddress = "StreetAddress";
export const Apartment = "Apartment";
export const State = "State";
export const Zipcode = "Zipcode";

export const Accepted = "Accepted";

export const Box1mLine1 = "Box1mLine1";
export const Box1mLine2 = "Box1mLine2";
export const Box1mLine3 = "Box1mLine3";
export const Box1mLine4 = "Box1mLine4";
export const Box3mLine1 = "Box3mLine1";
export const Box3mLine2 = "Box3mLine2";
export const Box3mLine3 = "Box3mLine3";
export const Box3mLine4 = "Box3mLine4";
export const Box6mLine1 = "Box6mLine1";
export const Box6mLine2 = "Box6mLine2";
export const Box6mLine3 = "Box6mLine3";
export const Box6mLine4 = "Box6mLine4";

export const UserDashboard = "UserDashboard";
export const DashboardTitle = "Dashboard";

export const ReferralCode = "ReferralCode";
export const Profile = "Profile";
export const MyOrders = "MyOrders";
export const ChangePassword = "ChangePassword";
export const InquiryTitle = "Inquiry";
export const CheckIn = "CheckIn";
export const TotalCheckIns = "TotalCheckIns";
export const FeelingText = "FeelingText";
export const AskSpecialist = "AskSpecialist";
export const Hello = "Hello";
export const WelcomeMessage = "WelcomeMessage";
export const Hi = "Hi";

export const Referrals = "Referrals";
export const RetakeTest = "RetakeTest";

export const ManageSubscription = "ManageSubscription";
export const CustomerService = "CustomerService";
export const Benefits = "Benefits";
