import React, { useEffect, useRef } from "react";
import { WIDGET_DATA } from "./ReviewsMobile";

const Reviews = () => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    const loadStyles = () => {
      if (!document.querySelector('link[href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2024062614"]')) {
        const style1 = document.createElement("link");
        style1.rel = "stylesheet";
        style1.href = "https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2024062614";
        document.head.appendChild(style1);
      }

      if (!document.querySelector('link[href="https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2024062614"]')) {
        const style2 = document.createElement("link");
        style2.rel = "stylesheet";
        style2.href = "https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2024062614";
        document.head.appendChild(style2);
      }
    };

    const loadScript = () => {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="https://widget.reviews.io/carousel-inline-iframeless/dist.js"]')) {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.src = "https://widget.reviews.io/carousel-inline-iframeless/dist.js";
        script.async = true;

        script.onload = () => {
          console.log("Script loaded");
          resolve();
        };

        script.onerror = () => {
          reject(new Error("Script load error"));
        };

        document.body.appendChild(script);
      });
    };

    const initializeWidget = () => {
      if (window.carouselInlineWidget) {
        try {
          new window.carouselInlineWidget(widgetContainerRef.current.id, WIDGET_DATA);
        } catch (error) {
          console.error("Error initializing widget:", error);
        }
      } else {
        console.error("window.carouselInlineWidget is not available");
      }
    };

    loadStyles();
    loadScript()
      .then(initializeWidget)
      .catch(error => {
        console.error(error);
      });

    return () => {
      const script = document.querySelector('script[src="https://widget.reviews.io/carousel-inline-iframeless/dist.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      <div id="reviewsio-carousel-widget" ref={widgetContainerRef}></div>
    </div>
  );
};

export default Reviews;
