import React from "react";
import { logo } from "../../Data/Images/Home";
import Language from "../../Layout/Header/Language";

const CustomQuizSuccess = () => {
  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <img src={logo} alt="" width={60} />
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <div className="t-w-full t-h-[80vh] product-suggestion-section section-padding t-flex t-flex-col t-items-center t-justify-center t-gap-4">
        <h2 className="text-center">Quiz completed successfully</h2>

        <h4 className="text-center">
          Thank you for taking your time to complete the quiz. Our health professional will get in touch with you soon.
        </h4>
        <a
          href="/"
          className="!t-text-base !t-px-8 t-py-2 btn-secondary !t-bg-secondary t-rounded-full"
        >
          Homepage
        </a>
      </div>
    </>
  );
};

export default CustomQuizSuccess;
