import { apiSlice } from "../app/api/apiSlice";

export const CustomFormulaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    customFormula: builder.mutation({
      query: (payload) => ({
        url: "custom-formula",
        method: "POST",
        body: { ...payload },
      }),
    }),
    getCustomFormula: builder.query({
      query: (quiz_id) => ({ url: `custom-formula?formula_id=${quiz_id}` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
  }),
});

export const { useCustomFormulaMutation, useGetCustomFormulaQuery } =
  CustomFormulaApiSlice;
