import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import { useAnswersMutation } from "../../features/quizApiSlice";
import { useLocation } from "react-router-dom";
import {
  getProductForWebAction,
  getSubsActions,
} from "../../app/api/useDataManage/subscriptionApis";
import { getCustomerProfileAction } from "../../app/api/useDataManage/userActions";
import { useTranslation } from "react-i18next";
import {
  AsSeenIn,
  YourFocusAreas,
  YourFocusAreasDesc,
  PersonalizedFormula,
  PersonalizedFormulaDesc,
  IncludesIngredients,
  Nutrient,
  Quantity,
  SubscriptionOptions,
  SubscriptionDetailsLine,
} from "../../Constants";
import { logo } from "../../Data/Images/Home";
import ProductSuggestionWhyRiise from "./ProductSuggestionWhyRiise";
import Language from "../../Layout/Header/Language";
import SubscriptionCard from "../SubscriptionCard";
import FocusAreaCard from "./components/FocusAreaCard";
import {
  ICONS_DATA,
  calculateIngredientPrice,
} from "../../utils/productSuggestions";
import MobileSubscriptionCard from "../MobileSubscriptionCard";
import AddIngredientModal from "./components/AddIngredientModal";
import TableRow from "./components/TableRow";
import Reviews from "./components/Reviews";
import ExpertSection from "./components/ExpertSection";
import useProduct from "../../hooks/useProduct";
import ReviewsMobile from "./components/ReviewsMobile";
import Whatsapp from "../../assets/whatsapp.png";
const ProductSuggestion = () => {
  let { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    onInputChange,
    removeIngredientFromBin,
    addIngredient,
    removeIngredient,
    onSubmitData,
    onRemoveQuantity,
    onAddQuantity,
    onSelectPlan,
    focusAreaImages,
    focusAreaIcons,
    allIngredients,
    allIngredientCodes,
    allIngredientPrices,
    allIngredientPriceLoading,
    isProductPricingLoading,
    onSelectOneOff,
    news,
    lang,
    setLang,
    data,
    setData,
    focusArea,
    setFocusArea,
    ingredients,
    setIngredients,
    quizId,
    setQuizId,
    user,
    setUser,
    quantitiesChanged,
    setQuantitiesChanged,
    error,
    setError,
    prices,
    setPrices,
    pricesData,
    setPricesData,
    totals,
    setTotals,
    continent,
    setContinent,
    paymentLink,
    setPaymentLink,
    subscribtion,
    setSubscription,
    token,
    addIngredientsModal,
    setAddIngredientsModal,
    newSubs,
    setNewSubs,
    ONE_OFF_SUB,
  } = useProduct();

  const selectedLang = useSelector(selectCurrentLanguage);
  const { subs } = useSelector((state) => state.subs);
  // const [isButtonVisible, setIsButtonVisible] = useState(true);
  const buttonRef = useRef(null);
  const boxRef = useRef(null);
  const subsRef = useRef();

  // All queries and mutations
  const [answers] = useAnswersMutation();

  // ALl USE EFFECTS HERE
  useEffect(() => {
    if (subs) {
      const tempSubs = subs.slice(1, subs.length);
      const sub = [ONE_OFF_SUB, ...tempSubs];
      setNewSubs(sub);
    }
  }, [subs, setNewSubs, ONE_OFF_SUB]);

  useEffect(() => {
    const receivedData = state;
    if (receivedData && receivedData.quiz_id > 0) {
      setQuizId(receivedData.quiz_id);
      localStorage.setItem("quiz_id", receivedData.quiz_id);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getSubsActions(lang));
    dispatch(getProductForWebAction());
    dispatch(getCustomerProfileAction(token));
  }, [dispatch]);

  // This will set the links to NO_EU if the country is in the list
  useEffect(() => {
    if (state) {
      let country = "";
      if (state?.slideValues[0]?.country?.value) {
        country = state?.slideValues[0]?.country?.value;
      } else if (state?.slideValues[8]?.country?.value) {
        country = state?.slideValues[8]?.country?.value;
      }
      if (
        country &&
        [
          "England",
          "Scotland",
          "Wales",
          "Northern Ireland",
          "Norway",
          "Switzerland",
        ].includes(country)
      ) {
        setContinent("non-eu");
      }
    }
  }, [state]);

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);
    setQuizId(localStorage.getItem("quiz_id"));
    dispatch(getSubsActions(lang));

    if (quizId > 0 && lang) {
      answers({ quiz_id: quizId, answers: null, lang: lang }) //.unwrap();
        .then((res) => {
          const { ingredients, focusArea, user } = res.data;
          setData(ingredients);
          setIngredients(ingredients);
          setFocusArea(focusArea);
          setUser(user?.user);
          // setLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });

      state = null;
    }
  }, [lang, selectedLang, quizId]);

  useEffect(() => {
    if (state && lang && state.quiz_id === undefined) {
      answers({ quiz_id: 0, answers: state, lang: lang }) //.unwrap();
        .then((res) => {
          const { quiz_id, ingredients, focusArea } = res.data;

          setQuizId(quiz_id);
          setData(ingredients);
          setIngredients(ingredients);
          setFocusArea(focusArea);
          localStorage.setItem("quiz_id", quiz_id);
          // setLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });

      state = null;
    }
  }, [state, lang]);

  // THis will calculate the initial prices for the ingredients
  useEffect(() => {
    if (!ingredients.length || !allIngredientPrices?.data?.length) return;
    setPrices({});
    ingredients.forEach((item) => {
      if (parseFloat(item.daily_dose) > 0) {
        calculateIngredientPrice(
          item,
          parseFloat(item.daily_dose),
          setPrices,
          setPaymentLink,
          setSubscription,
          allIngredientPrices
        );
        calculateIngredientPrice(
          item,
          parseFloat(item.daily_dose),
          setPricesData,
          setPaymentLink,
          setSubscription,
          allIngredientPrices
        );
      }
    });
  }, [ingredients, allIngredientPrices]);

  // THis will calculate the price for the product
  useEffect(() => {
    const totalSum = Object.values(prices).reduce(
      (sum, value) => sum + parseFloat(value),
      0
    );
    const shipping = totalSum >= 55 ? 0 : continent === "eu" ? 6 : 12;

    setTotals({
      oneMonth: totalSum + shipping,
      threeMonths: totalSum * 0.85, // 15% off of 1 month price + shipping
      sixMonths: totalSum * 0.7, // 30% off of 1 month price, free shipping
    });
  }, [prices]);

  // For hide/show mobile overlay next 2 useEffects
  useEffect(() => {
    const handleScrollToSection = () => {
      const scrollTo = subsRef?.current?.offsetTop;
      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    };

    const button = buttonRef.current;
    button.addEventListener("click", handleScrollToSection);

    return () => {
      button.removeEventListener("click", handleScrollToSection);
    };
  }, []);

  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <img src={logo} alt="" width={60} />
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <p
        className="t-text-center t-text-lg t-p-0 t-bg-primary t-text-black t-font-bold"
        style={{ marginBottom: "0px" }}
      >
        FREE shipping with any subscription
      </p>
      <div
        className="product-suggestion-section section-padding t-flex t-flex-col md:t-flex-row t-items-center t-gap-8"
        style={{ paddingTop: "10px" }}
      >
        <div className="t-relative container t-max-w-[1320px] t-mx-auto t-flex t-flex-col md:t-flex-row t-gap-8 md:t-gap-12">
          <div className="lg:t-w-[40%] t-flex t-flex-col t-gap-4">
            <div className="t-flex t-flex-col t-gap-0">
              <div className="t-flex t-items-center t-gap-8">
                <h2 className="!t-pb-0">
                  {user?.name.trim()}
                  {t(YourFocusAreas)}
                </h2>
              </div>
              <p>{t(YourFocusAreasDesc)}</p>
              <div className="t-flex t-flex-wrap t-gap-2">
                {focusArea &&
                  focusArea.map((item) => {
                    const focusImage = focusAreaImages?.data?.find(
                      (i) => i?.name === item?.answer
                    );
                    const focusIcon = focusAreaIcons?.data?.find(
                      (i) => i?.name === item?.answer
                    );
                    return (
                      <FocusAreaCard
                        icon={focusIcon?.url}
                        bg={focusImage?.url}
                        name={item.answer}
                        key={"focus-area-" + item.answer}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="t-flex t-flex-col t-gap-0">
              <h2>{t(PersonalizedFormula)}</h2>
              <p className="!t-mb-0">{t(PersonalizedFormulaDesc)}</p>
            </div>
            <div className="t-flex t-flex-wrap t-gap-2">
              {ICONS_DATA.map((item, i) => (
                <img
                  src={item}
                  alt=""
                  className="t-h-8 t-w-8"
                  key={"icons-data-" + i}
                />
              ))}
            </div>
            <div className="t-flex t-items-center t-justify-between t-gap-4">
              <h5 className="t-text-lg md:t-text-2xl">
                {t(IncludesIngredients).replace("{0}", data.length)}
              </h5>
              <div className="t-flex i-items-center t-gap-4">
                <button
                  className="t-group btn !t-w-fit !t-px-3 text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1"
                  onClick={() => {
                    setQuantitiesChanged(false);
                    setData(ingredients);
                    setError({});
                    setPrices(pricesData);
                  }}
                  disabled={!quantitiesChanged}
                >
                  Reset
                </button>
                <button
                  className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1 !t-px-3"
                  onClick={() => setAddIngredientsModal(true)}
                >
                  Add
                </button>
              </div>
            </div>

            <div className="table-responsive -t-mt-2">
              <table className="table accordion" id="accordionExample">
                <thead>
                  <tr>
                    <td className="t-text-[11px] md:t-text-sm t-w-[35%] md:t-w-[40%] t-font-bold">
                      {t(Nutrient)}
                    </td>
                    <td className="t-text-[11px] md:t-text-sm t-text-center t-font-bold">
                      {t(Quantity)}
                    </td>
                    <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                      %NRV
                    </td>
                    <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                      €
                    </td>
                    <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold"></td>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, i) => {
                      const ingredient = allIngredients?.data?.find(
                        (ing) =>
                          ing.ingredient === item?.ingredient ||
                          ing.ingredient_de === item?.ingredient
                      );
                      const ingredientData = allIngredientCodes?.data?.find(
                        (ing) => ing?.code === ingredient?.id
                      );
                      const euNrv = parseFloat(ingredientData?.eu_nrv);
                      return (
                        <TableRow
                          key={"ingredient-" + i}
                          item={item}
                          i={i}
                          onRemoveQuantity={onRemoveQuantity}
                          ingredientData={ingredientData}
                          onAddQuantity={onAddQuantity}
                          prices={prices}
                          removeIngredientFromBin={removeIngredientFromBin}
                          euNrv={euNrv}
                          onInputChange={onInputChange}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
            <section className="section-padding !t-pt-0 !t-pb-0">
              <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
                {t(AsSeenIn)}
              </h1>
              <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
                {news?.data?.map((item) => (
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
                    alt=""
                    className="t-w-16 t-h-auto md:t-w-[15%]"
                    key={"news-img-" + item.url}
                  />
                ))}
              </div>
            </section>
            <ProductSuggestionWhyRiise />
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>What our customers say</h2>
              <Reviews />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>Formulated by experts</h2>
              <ExpertSection lang={lang} />
            </div>
          </div>
          <div
            ref={subsRef}
            className="t-flex t-flex-col t-gap-4 !md:t-sticky !md:t-top-24 !t-h-fit sticky-top-products !t-pb-[100px]"
          >
            <h2 className="mb-0">{t(SubscriptionOptions)}</h2>
            <p className="t-text-sm t-mt-0 t-mb-1">
              {t(SubscriptionDetailsLine)}
            </p>
            <div className="t-hidden sm:t-flex t-flex-wrap t-justify-center t-items-stretch t-gap-4 t-pb-32 md:t-pb-0">
              {newSubs?.slice(0, 3).map((item, key) => (
                <SubscriptionCard
                  item={item}
                  onSelectPlan={onSelectPlan}
                  subscribtion={subscribtion}
                  id={key}
                  totals={totals}
                  key={"subscription-" + key}
                  loading={isProductPricingLoading || allIngredientPriceLoading}
                  onSelectOneOff={onSelectOneOff}
                  paymentLink={paymentLink}
                />
              ))}
            </div>
            <MobileSubscriptionCard
              username={user?.name.trim()}
              subscribtion={subscribtion}
              subs={newSubs}
              onSelectPlan={onSelectPlan}
              totals={totals}
              paymentLink={paymentLink}
              onSubmitData={onSubmitData}
              onSelectOneOff={onSelectOneOff}
              error={error}
              boxRef={boxRef}
            />
            <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>What our customers say</h2>
              <ReviewsMobile />
            </div>
            <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>Formulated by experts</h2>
              <ExpertSection lang={lang} />
            </div>

            <div className="t-hidden md:t-grid gap-3 mt-2">
              {paymentLink ? (
                <button
                  className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary"
                  onClick={() => onSubmitData(paymentLink)}
                  disabled={Object.values(error).some(
                    (value) => value === true
                  )}
                >
                  <div>start now</div>
                </button>
              ) : (
                <div className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary-disable">
                  <div>start now</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="t-flex t-flex-col t-items-center t-justify-center t-gap-2 md:t-hidden t-fixed t-bottom-0 t-bg-white t-z-40 t-w-full t-mx-auto t-py-4 "
          style={{ boxShadow: "10px 10px 20px 10px #efefef" }}
        >
          {paymentLink ? (
            <div
              ref={buttonRef}
              className="t-flex t-flex-col t-items-center t-justify-center t-gap-2"
            >
              <p className="t-text-xl !t-font-[600] !t-leading-normal !t-m-0">
                Selected Plan:{" "}
                {subscribtion?.title === "6 months"
                  ? Math.round(totals["sixMonths"])
                  : subscribtion?.title === "3 months"
                  ? Math.round(totals["threeMonths"])
                  : Math.round(totals["oneMonth"])}
                €
              </p>
              <button
                className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary"
                // href={`${paymentLink}?prefilled_email=${state?.user?.user?.email}&client_reference_id=${state?.user?.user?.id}`}
                onClick={() => onSubmitData(paymentLink)}
                disabled={Object.values(error).some((value) => value === true)}
              >
                <div>start now</div>
              </button>
            </div>
          ) : (
            <div ref={buttonRef}>
              <p className="t-text-xl !t-font-[600] !t-leading-normal !t-m-0 t-text-center">
                Up to {Math.round(totals["oneMonth"])}€
              </p>
              <button
                className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-md"
                onClick={() => {
                  const scrollTo = subsRef?.current.offsetTop;
                  window.scrollTo({
                    top: scrollTo,
                    behavior: "smooth",
                  });
                }}
              >
                Select a Plan
              </button>
            </div>
          )}
        </div>
        <a
          href="https://wa.me/33671498040"
          target="_blank"
          rel="noreferrer"
          className="t-fixed t-bottom-32 sm:t-bottom-5 t-right-5 t-z-50"
        >
          <img
            src={Whatsapp}
            alt=""
            className="t-h-10 t-w-10 md:t-h-15 md:t-w-15"
          />
        </a>
      </div>
      <AddIngredientModal
        open={addIngredientsModal}
        handleClose={() => setAddIngredientsModal(false)}
        data={data}
        removeIngredient={removeIngredient}
        addIngredient={addIngredient}
        allIngredients={allIngredients}
      />
    </>
  );
};

export default ProductSuggestion;
