import React, { useEffect, useState } from "react";
import Select from "react-select";

const CustomFormulaForm = ({
  name,
  setName,
  email,
  setEmail,
  discount_code,
  setDiscountCode,
  note,
  setNote,
  nutritionist,
  setNutritionist,
  onSaveFormula,
  isCreateCustomFormulaLoading,
  nutritionists,
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    if (nutritionists) {
      setOptions(
        nutritionists?.map((nutritionist) => ({
          value: nutritionist.code,
          label: nutritionist.code,
        }))
      );
    }
  }, [nutritionists]);

  const handleNutritionistChange = (selectedValue) => {
    setNutritionist(selectedValue.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "40px",
    }),
  };

  return (
    <div className="!t-w-full t-flex t-flex-col t-gap-4 t-items-center t-justify-center">
      <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
        <label className="t-text-gray-600 !t-ml-0">Customer name</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Enter customer name"
          className={`form-control t-h-[40px] !t-pl-3`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          minLength={3}
        />
      </div>
      <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
        <label className="t-text-gray-600 !t-ml-0">Customer email</label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Enter customer email"
          className={`form-control t-h-[40px] !t-pl-3`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          minLength={3}
        />
      </div>
      <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
        <label className="t-text-gray-600 !t-ml-0">Discount code</label>
        <input
          type="text"
          name="discount_code"
          id="discount_code"
          placeholder="Enter discount code (optional)"
          className={`form-control t-h-[40px] !t-pl-3`}
          value={discount_code}
          onChange={(e) => setDiscountCode(e.target.value)}
          minLength={3}
        />
      </div>
      <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
        <label className="t-text-gray-600 !t-ml-0">Note</label>
        <textarea
          type="text"
          name="note"
          id="note"
          placeholder="Enter note (optional)"
          className={`form-control t-h-[40px] !t-pl-3`}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          cols={5}
        />
      </div>
      <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
        <label className="t-text-gray-600 !t-ml-0">Practitioner code</label>
        <Select
          options={options}
          value={options?.find((c) => c.value === nutritionist)}
          onChange={handleNutritionistChange}
          className="!t-w-full"
          styles={customStyles}
        />
      </div>
      <button
        className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-lowercase btn-custom-quaternary"
        onClick={onSaveFormula}
        disabled={isCreateCustomFormulaLoading}
      >
        Save and send
      </button>
    </div>
  );
};

export default CustomFormulaForm;
