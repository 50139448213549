import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Buffer } from 'buffer';
import { logo } from '../Data/Images/Home';
import { useLoginMutation } from '../features/auth/authApiSlice';
import { setCredentials } from '../features/auth/authSlice';

import '../assets/scss/pages/_login.css';
import { useGetContentsQuery } from '../features/contentsApiSlice';

const Logins = () => {
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const idImage = Buffer.from('58').toString('base64');
  const [headerImage, setHeaderImage] = useState('');

  const [errMsg, setErrMsg] = useState('');

  const history = useNavigate();

  const loginAuth = async (e) => {
    e.preventDefault();
    setErrMsg('');

    if (email !== '' && password !== '') {
      try {
        const userData = await login({
          email: email,
          password: password,
        }).unwrap();

        dispatch(setCredentials({ ...userData, storage: true }));

        console.log(userData);

        const { user, expires_in } = userData;

        const auth0_profile = {
          name: user.name,
          email: user.email,
        };

        localStorage.setItem('login1', JSON.stringify(true));
        localStorage.setItem('authenticated1', JSON.stringify(true));

        const expiresIn = calculateExpiration(expires_in);
        localStorage.setItem('expires_in1', expiresIn);
        localStorage.setItem('auth1_profile', JSON.stringify(auth0_profile));
        localStorage.setItem('email1', user.email);
        localStorage.setItem('Name1', JSON.stringify(user.name));

        history(`/dashboard`);
        toast.success('Successfully logged in!..');
      } catch (error) {
        //console.log(err)
        if (error.status === 401 && error.data.message === 'Unauthorized.') {
          setErrMsg('Username or password is invalid!');
        }

        if (error.response) {
          console.log(error.message);
          console.log(error.response);
          //do something
        } else if (error.request) {
          console.log(error.request);
          //do something else
        } else if (error.message) {
          console.log(error.message);
          //do something other than the other two
        }
        //toast.error("You enter wrong password or username!..");
      }
    } else {
      toast.error('You enter wrong password or username!..');
    }
  };

  const {
    data: fetchHeaderImage,
    isSuccess: isSuccessImage,
  } = useGetContentsQuery({ id: idImage, lang: 'en', skip: false });

  useEffect(() => {
    if (isSuccessImage) {
      if (fetchHeaderImage.data.picture)
        setHeaderImage(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`
        );
    }
  }, [fetchHeaderImage, isSuccessImage]);

  return (
    <Container fluid={true} className='login-section section-padding pt-0' style={{
      backgroundImage: `linear-gradient(var(--secondary-80),var(--secondary-80)) , url(${headerImage})`,
    }}>
      <div className='container d-flex t-flex-col align-items-center justify-content-center vh-100 t-gap-20 md:-t-mt-10'>
        <div className='mlogo t-mb-4'>
          <a href='/'>
            <img src={logo} alt='' width={120} />
          </a>
        </div>
        <div className='t-w-full t-max-w-[400px] t-mx-auto t-bg-gray-50 t-flex t-flex-col t-gap-12 t-shadow-lg t-rounded-md !t-py-12 t-px-4'>
          <div className='t-text-2xl t-font-bold t-text-center t-text-tertiary'>
            Login in to your Riise account
          </div>
          <form action='' className='t-flex t-flex-col t-gap-8'>
            <div className='row textboxes'>
              <div className='col-12 position-relative'>
                <input
                  type='text'
                  className='form-control '
                  placeholder='Email'
                  aria-describedby='helpId'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <i className='fa fas fa-envelope !t-text-lg !t-mt-3' />
              </div>
            </div>
            <div className='row textboxes'>
              <div className='col-12 position-relative'>
                <input
                  type='password'
                  className='form-control'
                  placeholder='Password'
                  aria-describedby='helpId'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <i className='fa fas fa-key !t-text-lg !t-mt-3' />
              </div>
              <span className='d-block t-text-red-500 w-100 t-text-start'>
                {errMsg}
              </span>
            </div>
            <div className='row'>
              <div className='col-12 text-center'>
                <button
                  className='col-12 btn btn-custom-secondary fw-bold lts1 px-5'
                  onClick={(e) => loginAuth(e)}
                  disabled={isLoading}
                >
                  Login Here
                </button>
              </div>
            </div>
            <div className='t-flex t-items-center t-justify-between t-gap-2'>
              <a
                href={`/quiz`}
                className='t-text-tertiary'
              >
                <p className='t-text-tertiary'>Create an account</p>
              </a>
              <a href='/forgot-password'>
                <p className='t-text-tertiary'>Forgot password</p>
              </a>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Logins;

function calculateExpiration(ttl) {
  console.log(ttl);
  let timeStamps = 0;
  // Check if ttl is a number or a string
  if (typeof ttl === 'number') {
    // If it's a number, treat it as seconds
    const now = new Date();
    const expiration = new Date(now.getTime() + ttl * 1000); // Convert seconds to milliseconds
    timeStamps = expiration;
  } else if (typeof ttl === 'string') {
    // If it's a string, parse it to extract the duration and unit
    const duration = parseInt(ttl);
    const unit = ttl.slice(-1); // Get the last character (e.g., 'h', 'd')

    if (isNaN(duration)) {
      throw new Error('Invalid TTL format');
    }

    const now = new Date();

    switch (unit) {
      case 's':
        timeStamps = new Date(now.getTime() + duration * 1000);
        break;
      case 'm':
        timeStamps = new Date(now.getTime() + duration * 60000);
        break;
      case 'h':
        timeStamps = new Date(now.getTime() + duration * 3600000);
        break;
      case 'd':
        timeStamps = new Date(now.getTime() + duration * 86400000);
        break;
      default:
        throw new Error('Invalid TTL unit');
    }
  } else {
    throw new Error('Invalid TTL format');
  }

  return timeStamps.getTime();
}
